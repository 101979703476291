import Helper from '../../../../utils/Helper'
import StepsStore from '../StepsStore';

class ServicesFormStore extends StepsStore {

  constructor(){
    super();
    Object.keys( Helper.services ).forEach( item => {
      let service = Helper.services[item]
      this.inputs[ item ] = {
        label: service.label,
        type: 'checkbox',
        value: '',
        options: service.options, // list of options
        option: '', // selected option
        text: service.text,
      }
    });
    this.emitter.setMaxListeners(30);
  }

  getAllValues = () => {
    let keys = Object.keys( this.inputs );
    let services = {}
    keys.forEach( key => {
      services[key] = { 
        value: this.inputs[key].value,
        option: this.inputs[key].option,
      }
    })
    return { services: JSON.stringify(services) };
  }

  setValues = (defaults) => {
    if( Object.keys(defaults).length === 0 ) return;

    if( defaults.services ) defaults = JSON.parse(defaults.services);
    else defaults = {};

    if( Object.keys(defaults).length === 0 ) return;
    let keys = Object.keys( this.inputs );
    keys.forEach( key => {
      let {value, option} = defaults[key];
      if( value )
        this.update(key, value);
      if (option)
        this.setOption(key, option);
    })
    this.isReady();
  }
}

export default ServicesFormStore;