import React, { Fragment } from 'react';
import {
  FormControl, FormControlLabel,
  FormLabel, Radio, RadioGroup, Typography, TextField, MenuItem, InputLabel, Select, Checkbox
} from '@material-ui/core';


class CustomInput extends React.Component {

  name = this.props.name;
  store = this.props.store;
  type = null;

  state = {
    value: '',
    error: false,
    option: ''
  }

  componentDidMount() {
    this.updateValue({ field: this.name });
    this.store.subscribe(this.updateValue, this.handleError)
  }

  componentWillUnmount() {
    this.store.unsubscribe(this.updateValue, this.handleError)
  }

  handleError = (data) => {
    if (data.field !== this.name) return;
    let error = data.hasErrors;

    this.setState({ error });
  }

  updateValue = (data) => {
    if (!data || data.field !== this.name) return;

    let value = this.store.getValue(this.name);
    let option = this.store.getOption(this.name);
    this.setState({ value, option });
  }

  handleChange = (event) => {
    let value = (this.type === 'checkbox') ?
      event.target.checked
      : event.target.value;
    this.store.update(this.name, value)
  }

  handleOptionChange = (event) => {
    let option = event.target.value;
    this.store.setOption(this.name, option)
  }

  render() {
    let { name } = this.props;
    let { label, type, options, text } = this.store.getDetails(name);
    this.type = type;
    let display;
    switch (type) {
      case 'radio':
        display =
          <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup
              aria-label={label}
              name={name}
              value={this.state.value}
              onChange={this.handleChange}
            >
              {
                options.map((opt, idx) => {
                  return <FormControlLabel key={idx} value={opt.value} control={<Radio />} label={opt.label} />
                })
              }
            </RadioGroup>
          </FormControl>
        break;
      case 'select':
        display = <FormControl style={{ width: '90%' }}>
          <InputLabel htmlFor={`${name}-select`}>{label}</InputLabel>
          <Select
            value={this.state.value}
            onChange={this.handleChange}
            inputProps={{
              name: name,
              id: `${name}-select`,
            }}
          >
            <MenuItem value=""><em>Aucune</em></MenuItem>
            {
              Object.keys(options).map((opt, idx) => {
                return <MenuItem key={idx} value={opt}>{options[opt]}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        break;
      case 'date':
        display =
          <TextField
            id={name}
            label={label}
            value={this.state.value}
            onChange={this.handleChange}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        break;
      case 'textarea':
        display =
          <TextField
            id={name}
            label={label}
            value={this.state.value}
            onChange={this.handleChange}
            multiline
            fullWidth
            rows="2"
            margin="normal"
          />
        break;
      case 'text':
        display =
          <TextField
            id={name}
            label={label}
            value={this.state.value}
            onChange={this.handleChange}
            margin="normal"
          />
        break;
      case 'checkbox':
        display =
          <Fragment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.value}
                  onChange={this.handleChange}
                  value={label}
                />
              }
              label={label}
            />
            {
              options && this.state.value &&
              <div>
                <Select
                  value={this.state.option}
                  onChange={this.handleOptionChange}
                  fullWidth>
                  <MenuItem value=""><em>Aucune</em></MenuItem>
                  {
                    Object.keys(options).map((opt, idx) => {
                      return <MenuItem key={idx} value={opt}>{options[opt]}</MenuItem>
                    })
                  }
                </Select>
              </div>
            }
            {
              text && this.state.value &&
              <TextField
                label={'Spécifier'}
                value={this.state.option}
                onChange={this.handleOptionChange}
                margin="normal"
              />
            }
          </Fragment>;
        break;
      default:
        display = <Typography variant="caption" color="error">unknown type</Typography>
        break;
    }
    return (
      <Fragment>
        {
          display
        }
        {
          this.state.error && <Typography variant="caption" color="error">Merci de specifier ce champ</Typography>
        }
      </Fragment>
    );
  }
}


export default CustomInput;