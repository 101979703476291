import { EventEmitter } from 'events';
import Helper from '../../../../utils/Helper';
import StepsStore from '../StepsStore';


class MembershipFormStore extends StepsStore {

  emitter = new EventEmitter();

  inputs = {
    gender: {
      label: 'Sexe',
      type: 'radio',
      value: '',
      options: [
        {
          label: 'Fille',
          value: 'F',
        }, {
          label: 'Garçon',
          value: 'M',
        }
      ],
      regex: /.+/,
    },
    type_membre: {
      label: 'Handicap',
      type: 'radio',
      value: '',
      options: [
        {
          label: 'Adhérent',
          value: 'ADH',
        }, {
          label: 'Bénéficiaire',
          value: 'BEN',
        }
      ],
      regex: /.+/,
    },
    date_inscription: {
      label: 'Date Inscription',
      type: 'date',
      value: '',
      regex: /.+/,
    },
    dis_type: {
      label: 'Type d\'handicap',
      type: 'select',
      value: '',
      options: Helper.disabled_types,
      regex: /.+/,
    },
    suivi_hos: {
      label: 'Suivi hospitalier',
      type: 'textarea',
      value: '',
      regex: /.+/,
    },
    etablissement: {
      label: 'Etablissement',
      type: 'select',
      value: '',
      options: {
        elhouda: 'Elhouda Bouznika',
        public: 'Etb Public',
      },
      regex: /.+/,
    },
    address: {
      label: 'Adresse',
      type: 'textarea',
      value: '',
      regex: /.+/,
    },
    ville: {
      label: 'Ville',
      type: 'select',
      value: '',
      options: Helper.villes,
      regex: /.+/,
    },
    etab_other: {
      label: 'Si Public: Specifier',
      type: 'text',
      value: '',
      regex: /.*/,
    }
  }
}

export default MembershipFormStore;