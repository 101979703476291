import React, { Component, Fragment } from 'react';
import {
  Grid, withStyles
} from '@material-ui/core';
import { CustomInput } from '../../../../components'
import FormStore from './store';

const store = new FormStore();

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  }
});

class Personal extends Component {

  componentDidMount() {
    store.readySubscribe( this.stepReady )
    if( this.props.default ) {
      store.setValues(this.props.default);
    }
  }

  componentWillUnmount() {
    store.readyUnsubscribe(this.stepReady)
  }

  stepReady = (isReady) => {
    this.props.isValid( isReady );
    if( isReady ){
      this.props.collect( store.getAllValues() );
    }
  }

  render() {
    return (
      <Fragment>
        <Grid container>
          <Grid item xs>
            <CustomInput store={store} name="sociale" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="finance" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="assurance_maladie" />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Personal);