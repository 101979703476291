import { EventEmitter } from 'events';
import StepsStore from '../StepsStore';

class PersonalFormStore extends StepsStore {

  emitter = new EventEmitter();

  inputs = {
    nom: {
      label: 'Nom',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    prenom: {
      label: 'Prénom',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    num_dossier: {
      label: 'Dossier Nº',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    date_naissance: {
      label: 'Date Naissance',
      type: 'date',
      value: '',
      regex: /.+/,
    },
    rang_famille: {
      label: 'Rang dans la fratrie',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    tel: {
      label: 'Téléphone',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    patient_cin: {
      label: 'Patient CIN',
      type: 'text',
      value: '',
      regex: /.+/,
    },
    tuteur_cin: {
      label: 'Tuteur CIN',
      type: 'text',
      value: '',
      regex: /.+/,
    },
  }
}

export default PersonalFormStore;