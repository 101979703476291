import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../../layouts';
import List from './list';
import Form from './form';
import Payments from './payments';
import Files from './files';

const styles = theme => ({
  root: {
    // textAlign: 'center',
    // paddingTop: theme.spacing.unit * 20,
  },
});

class Main extends React.Component {

  state = {
    open: 'list',
    selected: undefined,
  }

  openForm = (selection) => {
    let selected = selection ? selection.pop() : undefined;
    this.setState({ open: 'form', selected })
  }

  closeForm = () => {
    this.setState({ open: 'list' })
  }

  openPayments = (selected) => {
    this.setState({ open: 'payments', selected });
  }


  openFiles = (selected) => {
    this.setState({ open: 'files', selected });
  }

  render() {
    const { classes } = this.props;
    const { open, selected } = this.state;
    let display;
    switch (open) {
      case 'form':
        display = <Form
          selected={this.state.selected}
          closeForm={this.closeForm} />;
        break;
      case 'payments':
        display = <Payments row={selected}
        closeForm={this.closeForm} />
        break;
      case 'files':
        display = <Files row={selected}
        closeForm={this.closeForm} />
        break;
      case 'list':
      default:
        display = <List openForm={this.openForm} openFiles={this.openFiles} openPayments={this.openPayments} />;
        break;
    }


    return (
      <div className={classes.root}>
        <Layout>
          {display}
        </Layout>
      </div>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Main);
