import { EventEmitter } from 'events';
import StepsStore from '../StepsStore';

class ExtrasFormStore extends StepsStore {

  emitter = new EventEmitter();

  inputs = {
    sociale: {
      label: 'Etat Sociale',
      type: 'select',
      value: '',
      regex: /.+/,
      options: {
        AISE: 'AISE',
        PRECAIRE: 'PRECAIRE',
      }
    },
    finance: {
      label: 'Sistuation Financiere',
      type: 'select',
      value: '',
      regex: /.+/,
      options: {
        Normal: 'Normal',
        Réduction: 'Réduction',
        Exonoré: 'Exonoré',
      }
    },
    assurance_maladie: {
      label: 'Assurance Maladie',
      type: 'select',
      value: '',
      regex: /.+/,
      options: {
        Mutuelle: 'Mutuelle',
        CNSS: 'CNSS',
        Ramed: 'Ramed',
        Sans: 'Sans',
      }
    },
  }
}

export default ExtrasFormStore;