import React, { Component, Fragment } from 'react';
import {
  Grid, Divider, withStyles
} from '@material-ui/core';
import { CustomInput } from '../../../../components'
import FormStore from './store';

const store = new FormStore();

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  }
});

class Personal extends Component {

  componentDidMount() {
    store.readySubscribe( this.stepReady )
    if( this.props.default ) {
      store.setValues(this.props.default);
    }
  }

  componentWillUnmount() {
    store.readyUnsubscribe(this.stepReady)
  }

  stepReady = (isReady) => {
    this.props.isValid( isReady );
    if( isReady ){
      this.props.collect( store.getAllValues() );
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <Fragment>
        <Grid container>
          <Grid item xs>
            <CustomInput store={store} name="nom" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="prenom" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="num_dossier" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="date_naissance" />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item xs>
            <CustomInput store={store} name="rang_famille" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="tel" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="patient_cin" />
          </Grid>
          <Grid item xs>
            <CustomInput store={store} name="tuteur_cin" />
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(Personal);