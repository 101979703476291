import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withRoot from '../../withRoot';
import {
  ArrowDropDown as SelectIcon,
  Save as SaveIcon, Cancel as CancelIcon,
} from '@material-ui/icons'
import {
  withStyles, Paper,
  Typography, Dialog, DialogTitle,
  ListItem, ListItemText, List,
  Grid, Button, Card, CardContent, Divider, FormControlLabel, Checkbox, DialogContent, FormControl, InputLabel, Input, InputAdornment, IconButton
} from '@material-ui/core';
import Helper from '../../utils/Helper'


const styles = theme => ({
  root: {
    // textAlign: 'center',
    padding: theme.spacing.unit * 2,
  },
});

const months = [
  {
    month: 9,
    label: 'Septembre'
  },
  {
    month: 10,
    label: 'Octobre'
  },
  {
    month: 11,
    label: 'Novembre'
  },
  {
    month: 12,
    label: 'Decembre'
  },
  {
    month: 1,
    label: 'Janvier'
  },
  {
    month: 2,
    label: 'Fevrier'
  },
  {
    month: 3,
    label: 'Mars'
  },
  {
    month: 4,
    label: 'Avril'
  },
  {
    month: 5,
    label: 'Mai'
  },
  {
    month: 6,
    label: 'Juin'
  },
  {
    month: 7,
    label: 'Juillet'
  },
];

class Payments extends React.Component {

  state = {
    openSelect: false,
    year: null,
    row: null,
    yearList: [],
    selectedYear: null,
    payments: {},
  }

  onSelect = (value, isNew = false) => {
    let { selectedYear, yearList, payments } = this.state;
    selectedYear = value;
    if (isNew === true) {
      yearList.push(selectedYear)
      payments[selectedYear] = {
        assurance: false,
        adhesion: false,
        monthly: {}
      }
      months.forEach(m => {
        payments[selectedYear].monthly[`${m.month}_service`] = false;
        payments[selectedYear].monthly[`${m.month}_atelier`] = false;
        payments[selectedYear].monthly[`${m.month}_transport`] = false;
      })
    };
    this.setState({ openSelect: false, selectedYear, yearList, payments });
  }

  componentDidMount() {
    let { row } = this.props;
    let { payments } = row;
    if (!payments) payments = {};
    else payments = JSON.parse(payments);
    let yearList = Object.keys(payments);
    let selectedYear;
    if (yearList.length > 0) selectedYear = yearList[yearList.length - 1];
    this.setState({ row, yearList, selectedYear, payments });
  }

  openYearList = () => {
    this.setState({ openSelect: true })
  }

  save = () => {
    let method = 'POST';
    let { row } = this.props;
    let url = '/members/single/' + row.id;
    let { payments } = this.state;
    row.payments = JSON.stringify(payments);
    Helper.DoRequest(url, {
      method,
      body: JSON.stringify(row)
    }).then((res) => {
      if (res === true) {
        this.props.closeForm();
      }
    })
  }

  paymentChange = (index, value) => {
    let { payments, selectedYear } = this.state;
    payments[selectedYear].monthly[index] = value;
    this.setState({ payments });
  }

  yearPaymentChange = name => event => {
    let { payments, selectedYear } = this.state;
    payments[selectedYear][name] = event.target.checked;
    this.setState({ payments });
  }

  render() {
    let { classes } = this.props;
    let { openSelect, selectedYear, yearList } = this.state;
    return (
      <Fragment>
        <Paper className={classes.root}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">
                Payments:
                <Button onClick={this.openYearList}>
                  {selectedYear}
                  <SelectIcon fontSize="small" />
                </Button>
                {
                  selectedYear && (
                    <Fragment>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={this.yearPaymentChange('assurance')}
                            checked={this.state.payments[this.state.selectedYear].assurance}
                          />
                        }
                        label="Assurance"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={this.yearPaymentChange('adhesion')}
                            checked={this.state.payments[this.state.selectedYear].adhesion}
                          />
                        }
                        label="Adhésion"
                      />
                    </Fragment>
                  )
                }

                <div style={{ float: 'right' }}>
                  <Button variant="contained" size="small" color="secondary" style={{ margin: 5 }}
                    onClick={this.props.closeForm}>
                    <CancelIcon />
                  </Button>
                  <Button variant="contained" size="small" color="primary" style={{ margin: 5 }}
                    onClick={this.save}>
                    <SaveIcon />
                  </Button>
                </div>
              </Typography>


              <Divider style={{ marginBottom: 15 }} />
            </Grid>
            <Grid item container>
              {
                selectedYear &&
                months.map(m => (
                  <Grid key={m.month} item md={3} sm={6} xs={12}>
                    <PayCard
                      onChange={this.paymentChange}
                      title={m.label}
                      index={m.month}
                      store={this.state.payments[this.state.selectedYear].monthly}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Paper>
        <ListDialog
          open={openSelect}
          onClose={this.onSelect}
          list={yearList}
          selectedValue={selectedYear}
        />
      </Fragment>
    );
  }
}


class ListDialog extends React.Component {

  state = {
    textInput: false,
    value: '',
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  addNew = () => {
    this.setState({ textInput: true })
  }
  handleChange = (event) => {
    let { value } = event.target;
    this.setState({ value })
  }
  selectNewValue = () => {
    let { value } = this.state;
    let { list } = this.props;
    let isNew = (list.indexOf(value) === -1);
    this.setState({ textInput: false, value: '' });
    this.props.onClose(value, isNew); // true means new value added
  }

  render() {
    const { classes, onClose, selectedValue, list, ...other } = this.props;
    let { textInput } = this.state;
    if (!textInput && list.length === 0) textInput = true;

    return (
      <Dialog aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">Selectionnée l'année</DialogTitle>
        {
          !textInput &&
          <div>
            <List>
              {list.map(item => (
                <ListItem button onClick={() => this.handleListItemClick(item)} key={item}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
              <ListItem button onClick={this.addNew}>
                <ListItemText primary="Ajouté" />
              </ListItem>
            </List>
          </div>
        }
        {
          textInput &&
          <DialogContent>
            <FormControl>
              <InputLabel>Année Scolaire</InputLabel>
              <Input
                autoFocus
                value={this.state.value}
                onChange={this.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="primary" onClick={this.selectNewValue}>
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={this.handleClose}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

          </DialogContent>
        }
      </Dialog>
    );
  }
}


class PayCard extends React.Component {

  state = {
    atelier: false,
    service: false,
    transport: false,
  }

  handleChange = name => event => {
    let { index } = this.props;
    this.setState({ [name]: event.target.checked });
    this.props.onChange(`${index}_${name}`, event.target.checked);
  };

  componentDidMount() {
    let {index} = this.props;
    this.setState({
      service: this.props.store[`${index}_service`],
      atelier: this.props.store[`${index}_atelier`],
      transport: this.props.store[`${index}_transport`],
    })
  }

  render() {
    let { title } = this.props;
    return (
      <Card style={{ padding: '0 10px', margin: 5 }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>{title}</Typography>
          <Divider />
        </CardContent>
        <Typography component="p">
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.service}
                onChange={this.handleChange(`service`)}
              />
            }
            label="Cotisation Service"
          />
        </Typography>
        <Typography component="p">
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.atelier}
                onChange={this.handleChange(`atelier`)}
              />
            }
            label="Cotisation Atelier"
          />
        </Typography>
        <Typography component="p">
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.transport}
                onChange={this.handleChange(`transport`)}
              />
            }
            label="Transport"
          />
        </Typography>
      </Card>
    )
  }
}


Payments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Payments));
