import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withRoot from '../../withRoot';
import {
  Save as SaveIcon, Cancel as CancelIcon,
  Delete as DeleteIcon, SaveAlt as DownloadIcon,
} from '@material-ui/icons'
import {
  withStyles, Paper,
  Typography,
  Grid, Button, Divider, Card, CardContent, CardActions, CardActionArea, CardMedia, IconButton,
} from '@material-ui/core';
import Helper from '../../utils/Helper'


const styles = theme => ({
  root: {
    // textAlign: 'center',
    padding: theme.spacing.unit * 2,
  },
});

class Files extends React.Component {

  state = {
    open: false,
    files: {
      // photo: null,
      // certificat: null,
      // projet: null,
      // extrait: null,
      // cine: null,
      // autre: null,
    },
    uploadList: {},
    filesToDelete: [],
  }

  componentDidMount() {
    let { row } = this.props;
    let { files } = row;
    files = JSON.parse(files);
    if (!files) files = {};
    this.setState({ files })
  }

  handleSelect = name => event => {
    let { uploadList } = this.state;
    uploadList[name] = event.target.files[0];
  }

  delete = name => {
    let { filesToDelete, files } = this.state;
    if( filesToDelete.indexOf( name ) === -1 ) filesToDelete.push(name);
    files[name] = null;
    this.setState( {filesToDelete, files} );
  }

  save = () => {
    let { uploadList, filesToDelete } = this.state;
    let { row } = this.props;
    var formData = new FormData();
    let names = Object.keys(uploadList);
    if (names.length === 0 && filesToDelete.length === 0) return this.props.closeForm();
    formData.append('id', row.id);
    formData.append('files', row.files);
    formData.append('uploads', names.join(','));
    formData.append('delete', filesToDelete.join(','));
    for (let i = 0; i < names.length; i++) {
      let name = names[i];
      formData.append(name, uploadList[name], uploadList[name].name);
    }
    Helper.DoRequest('/members/upload', {
      method: 'POST',
      body: formData
    }, true).then(ret => {
      if (ret) this.props.closeForm();
    })
  }

  render() {
    let { classes } = this.props;
    let { files } = this.state;
    let sizes = {
      xs: 12,
      sm: 6,
      md: 4,
    }
    return (
      <Fragment>
        <Paper className={classes.root}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5">
                Fichiers:
                <div style={{ float: 'right' }}>
                  <Button variant="contained" size="small" color="secondary" style={{ margin: 5 }}
                    onClick={this.props.closeForm}>
                    <CancelIcon />
                  </Button>
                  <Button variant="contained" size="small" color="primary" style={{ margin: 5 }}
                    onClick={this.save}>
                    <SaveIcon />
                  </Button>
                </div>
              </Typography>
              <Divider style={{ marginBottom: 15 }} />
            </Grid>
            <Grid container item spacing={16}>
              <Grid item {...sizes}>
                <FileField
                  title={"Photo"}
                  name={'photo'}
                  value={files.photo}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
              <Grid item {...sizes}>
                <FileField
                  title={"Certificat Médical"}
                  name={'certificat'}
                  value={files.certificat}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
              <Grid item {...sizes}>
                <FileField
                  title={"Extrait de naissance"}
                  name={'extrait'}
                  value={files.extrait}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
              <Grid item {...sizes}>
                <FileField
                  title={"CINE"}
                  name={'cine'}
                  value={files.cine}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
              <Grid item {...sizes}>
                <FileField
                  noPreview
                  title={"Projet Pédagogique"}
                  name={'projet'}
                  value={files.projet}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
              <Grid item {...sizes}>
                <FileField
                  noPreview
                  title={"Autre"}
                  name={'autre'}
                  value={files.autre}
                  onSelect={this.handleSelect}
                  delete={this.delete}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

class FileField extends React.Component {
  uniq = null;
  state = {
    previewSrc: null,
    fileBlob: null,
  }

  componentWillMount() {
    this.uniq = Math.random().toString(36).substr(2, 9);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value === prevProps.value) return;
    let { value } = this.props;
    if (!value) return;

    Helper.DoRequest(`/members/files`, {
      method: 'POST',
      body: JSON.stringify({ file: value })
    }, false, false
    ).then(fileBlob => {
      let previewSrc = URL.createObjectURL(fileBlob);
      //let blob = new Blob([csv], {type: "text/csv"});
      // window.navigator.msSaveOrOpenBlob(blob, fileName);
      this.setState({ previewSrc, fileBlob });
    })
  }

  render() {
    let { title, name, onSelect, value, noPreview } = this.props;
    let { previewSrc } = this.state;
    let filename;
    // eslint-disable-next-line
    if (value) filename = value.replace(/^.*[\\\/]/, '');

    let preview;
    if (!noPreview && previewSrc)
      preview =
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            image={previewSrc}
            title="Contemplative Reptile"
          />
        </CardActionArea>

    return (
      <Card>
        {preview}
        <CardContent>
          <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            {title}
          </Typography>

          {
            !value &&
            <input
              accept="pdf|image/*"
              onChange={onSelect(name)}
              type="file" />
          }
        </CardContent>
        {
          value &&
          <CardActions>
            <IconButton
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => this.props.delete(name)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              component="a"
              href={this.state.previewSrc}
              download={filename}
            >
              <DownloadIcon />
            </IconButton>
          </CardActions>
        }
      </Card>
    )
  }
}


Files.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Files));
