import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Wizard } from '../../components'
import { Membership, Personal, Besoin, Extras } from './steps'
import Helper from '../../utils/Helper';
import { LinearProgress } from '@material-ui/core';

const styles = theme => ({
  root: {
    // width: '90%',
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  }
});

class Form extends React.Component {

  state = {
    membership: false,
    personal: false,
    extras: false,
    profileData: {},
    isLoading: false,
  }

  updateWizardOnChange = (step, isReady) => {
    let obj = {}
    obj[step] = isReady;
    this.setState(obj)
  }

  collector = (data) => {
    let profileData = {
      ...this.state.profileData,
      ...data,
    }
    this.setState({ profileData });
  }

  sumbit = () => {
    let data = {
      ...this.state.profileData
    }
    let url = '/members';
    let method = 'POST';
    if (this.state.profileData.id && this.state.profileData.id > 0) {
      url = '/members/single/' + this.state.profileData.id;
    }
    Helper.DoRequest(url, {
      method,
      body: JSON.stringify(data)
    }).then((res) => {
      if (res === true) {
        this.props.closeForm();
      }
    })
  }

  componentDidMount() {
    let { selected } = this.props;

    if (selected) {
      this.setState({ isLoading: true });
      Helper
        .DoRequest(`/members/single/${selected}`)
        .then(profileData => {
          this.setState( {profileData, isLoading:false} )
        }, err => {
          console.log(err);
        })
    } else {
      this.setState({ profileData: {} });
    }
  }

  render() {
    const { closeForm } = this.props;
    if (this.state.isLoading) return <LinearProgress />
    return (
      <Fragment>
        <Wizard
          onComplete={this.sumbit}
          onCancel={closeForm}
          steps={[
            {
              label: 'Membreship',
              content: <Membership
                default={this.state.profileData}
                collect={this.collector}
                isValid={(isReady) => this.updateWizardOnChange('membership', isReady)}
              />,
              enableNext: this.state.membership,
            },
            {
              label: 'Information Personelle',
              content: <Personal
                default={this.state.profileData}
                collect={this.collector}
                isValid={(isReady) => this.updateWizardOnChange('personal', isReady)}
              />,
              enableNext: this.state.personal,
            },
            {
              label: 'Type de besoin',
              content: <Besoin
                default={this.state.profileData}
                collect={this.collector}
              />,
              enableNext: true,
            },
            {
              label: 'Extras',
              content: <Extras
                default={this.state.profileData}
                collect={this.collector}
                isValid={(isReady) => this.updateWizardOnChange('extras', isReady)}
              />,
              enableNext: this.state.extras,
            },
          ]}
        />
      </Fragment>
    );
  }
}

Form.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Form);
