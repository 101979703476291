import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Attachment, Payment } from '@material-ui/icons';
import withRoot from '../../withRoot';
import { Table, ConfirmationDialog } from '../../components'
import Helper from '../../utils/Helper'
import { IconButton, withStyles } from '@material-ui/core';


const styles = theme => ({
  root: {
    // textAlign: 'center',
    // paddingTop: theme.spacing.unit * 20,
  },
});

const headers = [
  { id: 'code', numeric: false, disablePadding: true, label: 'Code' },
  { id: 'nom_complet', numeric: false, disablePadding: false, label: 'Nom & Prénom' },
  { id: 'tel', numeric: false, disablePadding: false, label: 'Téléphone' },
  { id: 'disabled_types', numeric: false, disablePadding: false, label: 'Handicap' },
  { id: 'etablissement', numeric: false, disablePadding: false, label: 'Etablissement' },
  { id: 'actions', numeric: false, disablePadding: false, label: ' ' },
];

class List extends React.Component {
  state = {
    data: [],
    members: [],
    display: 'form',
    confirm: {
      open: false,
      title: '',
      text: '',
      onOk: null,
      isLoading: false,
    }
  }

  payments = (ev, membre) => {
    ev.stopPropagation();
    let { openPayments } = this.props;
    if( openPayments ) openPayments(membre);
  }

  files = (ev, membre) => {
    ev.stopPropagation();
    let { openFiles } = this.props;
    if( openFiles ) openFiles(membre);
  }

  componentDidMount() {
    console.log("mounted");
    this.refreshList();
  }

  refreshList = () => {
    Helper.DoRequest('/members/list')
      .then((list) => {
        let data = [], members = {};
        list.forEach(item => {
          members[item.id] = item;
          data.push(
            {
              id: item.id,
              code: Helper.getCode(item),
              nom_complet: `${item.nom} ${item.prenom}`,
              tel: item.tel,
              disabled_types: Helper.disabled_types[item.dis_type],
              etablissement: item.etablissement === "elhouda" ?
                "Elhouda Bouznika" : "Etb Public" + (item.etab_other ? `: ${item.etab_other}` : ''),
              actions: <Fragment>
                <IconButton color="primary" onClick={ (ev) => this.payments( ev, item ) }>
                  <Payment />
                </IconButton>
                <IconButton color="secondary" onClick={ (ev) => this.files( ev, item ) }>
                  <Attachment />
                </IconButton>
              </Fragment>
            }
          )
        })
        this.setState({ data, members })
      })
  }

  handleDeleteCommande = (selection) => {
    if (selection && selection.length > 0) {
      let { confirm } = this.state;
      confirm.open = true;
      confirm.title = "Confirmer la Suppression";
      confirm.text = "Etes-vous sure de vouloire supprimer la selection?"
      confirm.onOk = () => {
        let { confirm } = this.state;
        confirm.isLoading = true;
        this.setState({ confirm });
        this.deleteCommandes(selection)
      };
      this.setState({ confirm });
    }
  }

  deleteCommandes = (selection) => {
    let promises = []
    do {
      let member = this.state.members[selection.pop()];
      if (member && member.id) {
        promises.push(
          new Promise((res, rej) => {
            Helper.DoRequest('/members/single/' + member.id, {
              method: 'DELETE'
            }).then((result) => {
              if (result === true) {
                res();
              }
            })
          })
        )
      }
    } while (selection.length > 0);

    Promise.all(promises).then(() => {
      let { confirm } = this.state;
      confirm.isLoading = false;
      confirm.open = false;
      this.setState({ confirm });
      this.refreshList();
    })

  }

  onConfirmClose = () => {
    let { confirm } = this.state;
    confirm.open = false;
    this.setState({ confirm });
  }

  render() {
    // const { classes } = this.props;
    const { data } = this.state;
    const { openForm } = this.props;
    const { confirm } = this.state;

    return (
      <Fragment>

        <Table
          title={'List des adherents'}
          headers={headers}
          openForm={openForm}
          // openAddWindow={openForm}
          // openEditWindow={this.openCommandeEdit}
          handleDeleteCommande={this.handleDeleteCommande}
          data={data} />
        <ConfirmationDialog
          // { open, title, text, onCancel, onOk, onClose }
          open={confirm.open}
          title={confirm.title}
          text={confirm.text}
          onOk={confirm.onOk}
          onCancel={this.onConfirmClose}
          isLoading={confirm.isLoading}
        />
      </Fragment>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(List));
