import moment from 'moment';

function pad(num, size) {
    var s = String(num);
    while (s.length < (size || 2)) { s = "0" + s; }
    return s;
}

const apiUrl = process.env.REACT_APP_API_URL;
const Helper = {
    DoRequest: function (url, options, isUpload = false, isJson = true) {
        url = apiUrl + url;
        if (!options) options = {};
        if (!options.headers) options.headers = {};

        if (!options.headers["Content-Type"] && !isUpload)
            options.headers["Content-Type"] = 'application/json';

        if (Helper.isAuthenticated()) {
            options.headers["Authorization"] = "Bearer " + Helper.token();
            options.headers["Elhouda-Auth"] = "Bearer " + Helper.token();
        }

        var promise = new Promise(function (resolve, reject) {
            fetch(url, options)
                .then(function (response) {
                    if (response.status === 404) reject();
                    if (response.status === 401) {
                        Helper.logOut();
                        reject();
                    }
                    if (!response.ok) reject() //alert( "Error" );
                    if( isJson )
                        response.json()
                            .then(function (data) {
                                resolve(data);
                            }).catch((err) => {
                                reject(err);
                            });
                    if( !isJson )
                        response.blob()
                            .then( function (b ) {
                                resolve( b );
                            })
                    
                }).catch(function (err) {
                    reject(err);
                });

        });
        return promise;
    },
    disabled_types: {
        AUT: 'Autiste',
        MOT: 'Moteur',
        MEN: 'Mentale',
        T21: 'T21',
        IMC: 'IMC',
        DIS: 'Dislexie',
        VIS: 'Visuel',
        AUD: 'Auditf',
        BEG: 'Begument'
    },
    villes: {
        BOU: 'Bouznika',
        BEN: 'Bensliman',
        CAS: 'Casablanca',
        RAB: 'Rabat',
        MOH: 'Mohammedia',
        MEN: 'Mensouria',
        TEM: 'Temara',
        SKH: 'Skhirat',
        AAT: 'Ain Atiq',
        TAM: 'Tamesna',
        SAL: 'Salé',
        KEN: 'Kénitra',
        ELG: 'El Gara',
        AUT: 'Autre'
    },
    getCode: (profile) => {
        let code = '';
        code += pad(profile.id, 3)
        code += String(profile.prenom).slice(0, 1);
        code += String(profile.nom).slice(0, 1);
        code += profile.dis_type;
        let d = new moment(profile.date_naissance);
        code += d.format('DDMMYYYY').toString();
        if (profile.ville) code += profile.ville;
        code += profile.gender;
        let initialTypeMemeber = String(profile.type_membre).slice(0, 1)
        code += initialTypeMemeber.toUpperCase() === "A" ? "I" : "E";
        return code.toUpperCase();
    },
    services: {
        TPS: {
            label: 'Transport scolaire',
        },
        EDS: {
            label: 'Éducation spécialisée',
        },
        OTP: {
            label: 'Orthophonie',
        },
        KTR: {
            label: 'Kinésithérapie',
        },
        PCM: {
            label: 'Psychmotricité',
        },
        PSC: {
            label: 'Psychologie',
        },
        CPC: {
            label: 'Coupe courte',
        },
        BRD: {
            label: 'Broderie',
        },
        PTS: {
            label: 'Pâtisserie',
        },
        IFM: {
            label: 'Informatique',
        },
        ATP: {
            label: 'Atelier professionnel',
        },
        MDC: {
            label: 'Médicaments',
        },
        CCH: {
            label: 'Couches',
        },
        CSR: {
            label: 'Chaise roulante',
            options: {
                AD: 'Adulte',
                EF: 'Enfant',
            },
        },
        DMB: {
            label: 'Déambulateur',
        },
        BQL: {
            label: 'Béquilles',
            options: {
                AD: 'Adulte',
                EF: 'Enfant',
            },
        },
        APR: {
            label: 'Appareillage',
        },
        ATL: {
            label: 'Atèles',
        },
        LNT: {
            label: 'Lunettes',
        },
        APA: {
            label: 'Appareils auditifs',
        },
        FNP: {
            label: 'Financement Projet',
        },
        ADA: {
            label: 'Aides alimentaires',
        },
        ATR: {
            label: 'Autre',
            text: true,
        },
    },
    monthlypayments: () => {
        let months = [
            {
                month: 9,
                label: 'Septembre'
            },
            {
                month: 10,
                label: 'Octobre'
            },
            {
                month: 11,
                label: 'Novembre'
            },
            {
                month: 12,
                label: 'Decembre'
            },
            {
                month: 1,
                label: 'Janvier'
            },
            {
                month: 2,
                label: 'Fevrier'
            },
            {
                month: 3,
                label: 'Mars'
            },
            {
                month: 4,
                label: 'Avril'
            },
            {
                month: 5,
                label: 'Mai'
            },
            {
                month: 6,
                label: 'Juin'
            },
        ];
        let currentMonth = new Date().getMonth() + 1;
        if( currentMonth > 6 && currentMonth < 9 ) return months;
        if( currentMonth >= 9 ) {
            let retMonths = [];
            months.forEach((m)=>{
                if( m.month >= 9 && m.month <= currentMonth ) 
                    retMonths.push(m);
            })
            return retMonths;
        }
        if( currentMonth <= 6) {
            let retMonths = [];
            months.forEach((m)=>{
                if( m.month >= 9 || m.month <= currentMonth ) 
                    retMonths.push(m);
            })
            return retMonths;
        }
    },
    isAuthenticated: () => {
        return Helper.token() !== null;
    },
    setAuthenticated: (token) => {
        localStorage.setItem('data', token)
    },
    token: () => {
        return localStorage.getItem('data');
    },
    logOut: () => {
        localStorage.removeItem('data');
        window.location.reload(true);
    }
}
export default Helper;