import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withRoot from '../withRoot';
import Helper from '../utils/Helper';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router'
import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignIn extends React.Component {
  state = {
    email: null,
    password: null,
    isLoading: false,
    isAuthenticated: Helper.isAuthenticated()
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    let newState = {};
    newState[name] = value;
    this.setState(newState);
  }

  doLogin = (event) => {
    event.preventDefault();
        // start loading mask
        this.setState({ isLoading: true });

        const { username, password } = this.state
        let data = { username: username, password: password };
        Helper.DoRequest("/login", {
            method: "POST",
            body: JSON.stringify(data)
        }, false)
            .then((response) => {
                if (response.error)
                    return this.setState({ error: response.message, isLoading: false });
                if (response.token){
                    Helper.setAuthenticated(response.token);
                    return this.setState({ isLoading: false, isAuthenticated: Helper.isAuthenticated() })
                }
            }).catch( (err)=>{
                return this.setState({ error: "Mauvais utilisateur ou mot de passe", isLoading: false });
            })
  }


  render() {
    const { classes } = this.props;
    if( this.state.isAuthenticated ) return <Redirect to="/" />
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>

          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            El Houda Handicap
          </Typography>
          <form className={classes.form} onSubmit={this.doLogin}>
            {
              this.state.isLoading &&
              <LinearProgress />
            }

            {
              this.state.error &&
              <Typography color="error" variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                <ErrorIcon fontSize="small" />
                {this.state.error}
              </Typography>
            }

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Login</InputLabel>
              <Input id="username" name="username" autoComplete="username" autoFocus
                onChange={this.handleChange}
                disabled={this.state.isLoading} />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Mot de passe</InputLabel>
              <Input name="password" type="password" id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
                disabled={this.state.isLoading} />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.isLoading}
            >
              Login
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(SignIn));
