import { EventEmitter } from 'events';

class StepsStore {

  emitter = new EventEmitter();
  inputs = {};

  validate = (field, fire = true) => {
    let { value, regex } = this.inputs[field];
    let hasErrors = false;
    if( regex && !value.match(regex) ) {
      hasErrors = true;
    }
    if( fire ) this.emitter.emit( 'error', {field, hasErrors});
    this.emitter.emit('ready', false);
    return hasErrors;
  }

  getDetails = field => {
    let { label, type, options, text } = this.inputs[field];
    return { label, type, options, text };
  }

  getValue = field => {
    return this.inputs[field].value
  }

  subscribe = (onChange, onError) => {
    this.emitter.on('update', onChange);

    if( onError ) this.emitter.on('error', onError);
  }

  unsubscribe = (onChange, onError) => {
    this.emitter.removeListener('update', onChange);
    this.emitter.removeListener('error', onError);
  }

  update = (field, value) => {
    this.inputs[field].value = value;
    this.emitter.emit('update', {field});
    this.validate(field);
    this.isReady()
  }

  getOption = field => {
    return this.inputs[field].option
  }

  setOption = (field, option) => {
      this.inputs[field].option = option;
      this.emitter.emit('update', {field});
  }

  readySubscribe = (callback) => {
    this.emitter.on('ready', callback);
  }

  readyUnsubscribe = (callback) => {
    this.emitter.removeListener('ready', callback);
  }

  isReady = () => {
    if( this.validateStep( false ) )
      this.emitter.emit('ready', true);
  }

  validateStep = (fire = true) => {
    let keys = Object.keys( this.inputs );
    for( let i = 0; i < keys.length ; i++ ) {
      let field = keys[i];
      if( this.validate( field, fire ) ) return false;
    }
    return true;
  }

  getAllValues = () => {
    let keys = Object.keys( this.inputs );
    let data = {}
    keys.forEach( key => {
      data[key] = this.inputs[key].value
    })
    return data;
  }

  setValues = (defaults) => {
    if( Object.keys(defaults).length === 0 ) return;
    let keys = Object.keys( this.inputs );
    keys.forEach( key => {
      let value = defaults[key];
      if( value )
        this.update(key, value);
    })
    this.isReady();
  }

}

export default StepsStore;