import React, { Component, Fragment } from 'react';
import {
  Grid, Divider, withStyles, Typography
} from '@material-ui/core';
import { CustomInput } from '../../../../components'
import Helper from '../../../../utils/Helper'
import FormStore from './store';

const store = new FormStore();

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  }
});

class Personal extends Component {

  componentDidMount() {
    store.readySubscribe(this.stepReady)
    if( this.props.default ) {
      store.setValues(this.props.default);
    }
  }

  componentWillUnmount() {
    store.readyUnsubscribe(this.stepReady)
  }

  stepReady = (isReady) => {
    if (isReady) {
      this.props.collect(store.getAllValues());
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <Fragment>
        <Typography variant="subtitle1">Type de besoin</Typography>
        <Grid container>
          {
            Object.keys(Helper.services).map(item => {
              return (
                <Grid key={item} item xs={2}>
                  <CustomInput store={store} name={item} />
                </Grid>
              )
            })
          }
        </Grid>
        <Divider className={classes.divider} />
      </Fragment>
    )
  }
}

export default withStyles(styles)(Personal);